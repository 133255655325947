import React, {Component} from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import {Redirect} from 'react-router-dom';
import LoadingReceipt from './LoadingReceipt';
import { getDatabase, ref, onValue} from "firebase/database";


class Receipt extends Component {
 
  constructor() {
    super()

    if (localStorage.getItem('sessionId') === null) {
      window.location.reload(false);
    }

    this.state = {
      receipt: [],
      redirect: '',
      title: ''
    }

    const openAppRoute = (redirectUrl) => {
      this.unmountScript();
      this.setState({
        redirect: redirectUrl
      });
    };
    window.openAppRoute = openAppRoute;

    this._isMounted = false;
  }

  unmountScript()
  {
    try
    {
      window.clear_timer();
    }
    catch(error)
    {

    }

    try
    {
      document.getElementById("scriptReceipt").remove();
    }
    catch(error)
    {

    }
    
  }

  fetchApi()
  {
    const scriptReceipt = document.createElement("script");
    scriptReceipt.src = process.env.REACT_APP_API_URL +"/receipt/jscript";
    scriptReceipt.async = false;
    scriptReceipt.setAttribute("id",'scriptReceipt');
    document.body.appendChild(scriptReceipt);

    fetch(process.env.REACT_APP_API_URL+"/receipt/"+ this.props.match.params.id +"/"+this.props.match.params.slug)
      .then(response => response.json())
      .then((json) => {
        
        

        this.stopListenerReceipt();
        this.startListenerReceipt();

       }).catch((e) => {
        this.stopListenerReceipt();
        this.setState({
          redirect: '/page/not/found'
        });
      })
      
  }

  startListenerReceipt()
  {
    const db = getDatabase();
    const starCountRef = ref(db, 'receipt/'+ this.props.match.params.id +'/'+ this.props.match.params.slug +'/receipt');
    let listen = onValue(starCountRef, (snapshot) => {


      if (snapshot.exists()) {
        
        this.setState({
          receipt: snapshot.val()
        });


        this.setState({
          title: 'Receipt '+ snapshot.val().confirmation_code
        });

        if(snapshot.val().booking_status==="PENDING")
        {
          window.payment_timer(snapshot.val().due_date,this.props.match.params.id,this.props.match.params.slug);
        }
        

       
        
      
      } else {

        listen();
        this.setState({
          redirect: '/page/not/found'
        });

      }

    });
  }

  stopListenerReceipt()
  {
    const db = getDatabase();
    const starCountRef = ref(db, 'receipt/'+ this.props.match.params.id +'/'+ this.props.match.params.slug +'/receipt')
    let listen = onValue(starCountRef, (snapshot) => {
    });
    listen();
  }


  componentDidMount() {
    this._isMounted = true;
    this.fetchApi();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
   this._isMounted = false;
   this.stopListenerReceipt();
   this.unmountScript();
  }

  render(){
  
  if(this.state.redirect!==""){
    let redirectUrl = this.state.redirect;
    return <Redirect to={redirectUrl} />
  }
  
  if(this.state.receipt.length===0)
    {
      return <LoadingReceipt />
    }


return (
<section style={{ backgroundColor: "#ffffff" }}>
<HelmetProvider>
<Helmet>
  <title>{this.state.title}</title>
        <meta name="description" content={ localStorage.getItem('siteDescription') } />

        <meta property="og:type" content="website"/>
        <meta property="og:title" content={this.state.title} />
        <meta name="og:description" content={ localStorage.getItem('siteDescription') } />

        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content={this.state.title}/>
        <meta name="twitter:description" content={ localStorage.getItem('siteDescription') } />
</Helmet>
</HelmetProvider>


<div id="receipt" className="container">
  
  <div className="row">
    <div className="col-lg-12 col-md-12 mx-auto">
      <div className="row" style={{ paddingBottom: "0px"}}>
        <div className="col-lg-12 text-left">
          <div style={{ height: "70px" }} />

          <div className="card mb-8 shadow p-2 mt-2">
            <div className="card-body" style={{ 
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "15px"
            }}>
              <div className="col-md-12  mx-auto text-left">
                  <input type="hidden" id="timer_id" />
                  <h4>Your booking references is {this.state.receipt.confirmation_code}</h4>
                  <div dangerouslySetInnerHTML={ {__html: this.state.receipt.header} } />
                
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-lg-6 col-lg-auto mb-6">
              <div className="card shadow mt-4">
                <div className="card-header bg-dark text-white pb-1">
                  <h4><i className="fas fa-file-invoice"></i> Booking Detail</h4>
                </div>
                <div className="card-body">
                    <h3>Booking Status</h3>
                    <div dangerouslySetInnerHTML={ {__html: this.state.receipt.booking_status_asText} } />
                     
                     {(() => {
                     if(this.state.receipt.payment_status !== undefined)
                     {
                          return (
                              <div dangerouslySetInnerHTML={ {__html: this.state.receipt.payment_status} } />
                            )
                     }
                     })()}

                     {(() => {
                     if(this.state.receipt.product_detail !== undefined)
                     {
                          return (
                              <div dangerouslySetInnerHTML={ {__html: this.state.receipt.product_detail} } />
                            )
                     }
                     })()}

                     {(() => {
                     if(this.state.receipt.cancel_booking !== undefined)
                     {
                          return (
                              <div dangerouslySetInnerHTML={ {__html: this.state.receipt.cancel_booking} } />
                            )
                     }
                     })()}

                     {(() => {
                     if(this.state.receipt.how_to_pay !== undefined)
                     {
                            
                            return (
                                <div>
                                  <h3>How to pay?</h3>
                                  <div dangerouslySetInnerHTML={ {__html: this.state.receipt.how_to_pay} } />
                                </div>
                              );
                     } 
                     })()}
                </div>
              </div>


              {(() => {
                if(this.state.receipt.ext_box1 !== undefined)
                {
                  return (
                      <div dangerouslySetInnerHTML={ {__html: this.state.receipt.ext_box1} } />
                    )
                }
              })()}


              
            </div>

            <div className="col-lg-6 col-lg-auto mb-6 mt-4">

              <div className="card shadow">
                <div className="card-header bg-dark text-white pb-1">
                  <h4><i className="fas fa-file"></i> Travel Documents</h4>
                </div>
                
                <div className="card-body">
                  
                    <h3>Receipt</h3>
                    <div dangerouslySetInnerHTML={ {__html: this.state.receipt.invoice} } />
                    
                  
                </div>

              </div>

              <div className="card shadow mt-4">
                <div className="card-header bg-dark text-white pb-1">
                  <h4><i className="fas fa-user-tie"></i> Customer Info</h4>
                </div>
                <div className="card-body">
                    
                    
                    {(() => {
                      if(this.state.receipt.firstName !== "" && this.state.receipt.email !== undefined)
                      {
                        if(this.state.receipt.lastName !== "" && this.state.receipt.email !== undefined)
                        {
                          return (
                            <div>
                              <h3>Name</h3>
                              <div dangerouslySetInnerHTML={ {__html: this.state.receipt.firstName +" "+ this.state.receipt.lastName} } />
                            </div>
                          )
                        }
                        else
                        {
                          return (
                            <div>
                              <h3>Name</h3>
                              <div dangerouslySetInnerHTML={ {__html: this.state.receipt.firstName } } />
                            </div>
                          )
                        }
                        
                      }
                    })()}

                    {(() => {
                      if(this.state.receipt.phoneNumber !== "" && this.state.receipt.email !== undefined)
                      {
                        return (
                          <div>
                          <h3>Phone</h3>
                          <div dangerouslySetInnerHTML={ {__html: this.state.receipt.phoneNumber} } />
                          </div>
                        )
                      }
                    })()}

                    {(() => {
                      if(this.state.receipt.email !== "" && this.state.receipt.email !== undefined)
                      {

                        return (
                          <div>
                          <h3>Email</h3>
                          <div dangerouslySetInnerHTML={ {__html: this.state.receipt.email} } />
                          </div>
                        )
                      }
                    })()}
                    

                </div>
              </div>

              {(() => {
                if(this.state.receipt.ext_box2 !== "" && this.state.receipt.email !== undefined)
                {
                  return (
                      <div dangerouslySetInnerHTML={ {__html: this.state.receipt.ext_box2} } />
                    )
                }
              })()}


            </div> 
          </div>
          
        </div>
      </div>
    </div>
  </div>
  <div style={{ height: "50px" }} />
</div>
</section>

      )

  }
}

export default Receipt;